import { CmsPollDetailsComponent } from './details/cms-polls-details.component';
// Angular Things
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Feature Routing Module
import { CmsRoutingModule } from './cms-routing.module';

// Shared Module
import { SharedModule } from '../shared/shared.module';

// Components
import { CmsComponent } from './cms.component';
import { CmsVolumesComponent } from './details/cms-volumes.component';
import { CmsVolumeDetailComponent } from './details/cms-volume-details.component';
import { CmsOnpointDetailComponent } from './details/cms-onpoint-details.component';
import { CmsModulesComponent } from './details/cms-modules.component';
import { CmsModuleDetailComponent } from './details/cms-module-details.component';
import { CmsIqListComponent } from './details/cms-iq-list.component';
import { CmsIqEditComponent } from './details/cms-iq-edit.component';
import { CmsExpertComponent } from './details/cms-expert.component';
import { CmsExpertDetailComponent } from './details/cms-expert-details.component';
import { CMSReportingComponent } from 'app/cms/reports/cms-reports.component';
import { CmsInventoryListComponent } from './details/cms-inventory-list.component';
import { CmsTipsheetListComponent } from './details/cms-tipsheet-list.component';
import { CmsTipsheetEditComponent } from './details/cms-tipsheet-edit.component';
import { CmsCpdComponent } from './details/cms-cpd.component';
import { CmsPollsComponent } from './details/cms-polls.component';
import { CmsExpertReviewComponent } from './details/cms-expert-review.component';
import { CanDeactivateGuard } from '../services/canDeactivateGuard.service';

// Third Party
import { FileUploadModule } from 'ng2-file-upload';
import { CmsService } from 'app/cms/cms.service';
import { CategoriesService } from 'app/categories/categories.service';
import { CMSManageSiteText } from './details/cms-manage-text.component';
import { CMSManageTextDetails } from './details/cms-manage-text-details.component';
import { CmsCpdVolumesComponent } from './details/cms-cpd-volumes.component';
import { CmsCanadaReportTemplateListComponent } from './details/cms-canada-report-template-list.component';
import { CmsCanadaReportTemplateEditComponent } from './details/cms-canada-report-template-edit.component';
import { CmsCanadaReportBuildComponent } from './details/cms-canada-report-build.component';
import { CmsHelpComponent } from './details/cms-help.component';
import { CmsHelpEditComponent } from './details/cms-help-edit.component';
import { TagSelectorComponent } from './details/tag-selector.component';
import { CmsImageAssetsComponent } from './details/cms-images.component';
import { CMSExpertSpecialities } from './details/cms-expert-specialities.component';
import { MatTabsModule} from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule} from '@angular/material/button';
import { CmsCampaignListComponent } from './details/cms-campaign/cms-campaign-list/cms-campaign-list.component';
import { CmsCampaignDetailsComponent } from './details/cms-campaign/cms-campaign-details/cms-campaign-details/cms-campaign-details.component';
import { DragulaModule } from 'ng2-dragula';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { CmsLearningHubComponent } from './details/learninghub-list.component';
import { CmsLearningHubDetailsComponent } from './details/learninghub-details.component';
import { LearningHubService} from 'app/services/learninghub.service';
import { SpeechRecognitionService} from 'app/services/speechRecognition.service';
import { VideoParticipationComponent } from './details/video-participation.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CmsSubcategoryListComponent } from './details/cms-subcategory/cms-subcategory-list/cms-subcategory-list.component';
import { CmsSubcategoryDetailsComponent } from './details/cms-subcategory/cms-subcategory-details/cms-subcategory-details.component';
import { CmsSubtitlingExportComponent } from './details/cms-subtitling-export.component';
import { CmsSubtitlingImportComponent } from './details/cms-subtitling-import.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  imports: [SharedModule, CmsRoutingModule, MatTabsModule, BrowserAnimationsModule,
  MatButtonModule, FileUploadModule, ReactiveFormsModule, DragulaModule.forRoot()
  , MatFormFieldModule, MatDatepickerModule, MatNativeDateModule, MatInputModule, MatAutocompleteModule, MatCheckboxModule, DragDropModule ],
  declarations: [
    CmsComponent,
    CMSReportingComponent,
    CmsVolumesComponent,
    CmsModulesComponent,
    CmsVolumeDetailComponent,
    CmsOnpointDetailComponent,
    TagSelectorComponent,
    CmsModuleDetailComponent,
    CmsIqListComponent,
    CmsIqEditComponent,
    CmsExpertComponent,
    CmsExpertDetailComponent,
    CmsInventoryListComponent,
    CMSManageSiteText,
    CMSManageTextDetails,
    CmsTipsheetListComponent,
    CmsTipsheetEditComponent,
    CmsExpertReviewComponent,
    CmsCpdComponent,
    CmsCpdVolumesComponent,
    CmsPollsComponent,
    CmsPollDetailsComponent,
    CmsCanadaReportTemplateListComponent,
    CmsCanadaReportTemplateEditComponent,
    CmsCanadaReportBuildComponent,
    CmsHelpComponent,
    CmsHelpEditComponent,
    CmsImageAssetsComponent,
    CMSExpertSpecialities,
    CmsCampaignListComponent,
    CmsCampaignDetailsComponent,
    CmsLearningHubComponent,
    CmsLearningHubDetailsComponent,
    VideoParticipationComponent, 
    CmsSubcategoryListComponent, 
    CmsSubcategoryDetailsComponent,
    CmsSubtitlingExportComponent,
    CmsSubtitlingImportComponent
  ],
  providers: [
    CmsService, 
    CategoriesService, 
    MatDatepickerModule, 
    MatNativeDateModule, 
    CmsService, 
    CategoriesService,
    LearningHubService,
    SpeechRecognitionService,
    CanDeactivateGuard
  ]
})
export class CmsModule { }
