// Angular Things
import { Component, HostBinding, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

// Services
import { CmsService } from '../cms.service';
import { CategoriesService } from '../../categories/categories.service';
import { UtilitiesService } from '../../shared/utilities.service';
import { ConfirmService } from '../../shared/confirm.service';
import { PopupService } from '../../shared/popup.service';
import { SnackbarService } from 'app/services/Snackbar.service';

// Classes
import { VolumeDetailItem } from '../../shared/VolumeDetailItem';
import { SimpleVolume } from '../../shared/SimpleVolume';
import { ModuleItem } from '../../shared/ModuleItem';
import { Expert } from '../../shared/expert';
import { Categories } from '../../categories/categories';
import { AiPromptType } from '../../shared/objects/aiPrompts';
import { EmsConfig } from '../../shared/emsConfig';

// Components
import { CmsModuleDetailComponent } from './cms-module-details.component';

// Third-Party Things
import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { VolumeDetailList } from 'app/shared/VolumeDetailList';
import { SubCategory } from 'app/shared/SubCategory';

declare var tinymce: any;

@Component({
  selector: 'cms-onpoint-details', // this renames the undefined element after router-outlet in the dom
  templateUrl: 'cms-onpoint-details.component.html',
  providers: [Location]
})
export class CmsOnpointDetailComponent implements OnInit, OnDestroy {
  // variables for getting account id from url
  id: number;

  private sub: any;

  errorMessage: string;

  onpointDetails: VolumeDetailItem;
  onpointModule: ModuleItem;
  onPointDataIsLoaded:boolean;
  // matched volume variables
  simpleVolumes: any;
  matchedVolLang: string;
  showMatchedReadOnly: boolean;

  updateModuleOnly: boolean = false;

  addNewVolume = false;
  newVolume: VolumeDetailItem;
  newModule: ModuleItem = new ModuleItem();

  expertList: any;
  tags: any[] = [];
  tagBroadcast: Subject<any> = new Subject();
  newOnpointID: any;

  storedCategories: any;
  storedInventories: any;

  showAddModule: boolean;
  volumeCreated: boolean;
  moduleDetails: any;

  videoLocation: string='';

  // img stuff
  imgPreviewUrl: string;
  showImgPreview: boolean = false;
  imageToUpload: string;
  resendImage: boolean = false;
  imageLoaded: boolean;
  imageInQueue: boolean;
  inDatabaseQueue: boolean;
  unprocessedImages: any;

  aiPromptType = AiPromptType;
  
  // for category lists
  public categoryList: Categories[];

    //for subcategory list
    subCategories: SubCategory[] = [];
    videoSubcategoryIDs: any = {};
    categoryName:string;
    

  confirmData: {};
  confirmOnpointData: any;
  confirmOnpointModuleData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  onPointInitialStatus: string;
  showOnpointStatusConfirmation: boolean;

  onpointPreviewLink: string;
  showPreviewLink: boolean = false;

  public get AmazonIsUploadedString(): string {
    return this.onpointModule !== undefined && this.onpointModule.AmazonIsUploaded ? 'Amazon Video Uploaded' : 'Amazon video not uploaded';
  }

  public get BrightcoveIsUploadedString(): string {
    if (this.onpointModule !== undefined && this.onpointModule.BrightCoveID != '-1')
      return 'Video is in brightcove with id ' + this.onpointModule.BrightCoveID
    else {
      return 'Brightcove Video Not Uploaded';
    }
  }

  showTipsheetDropzone: boolean;
  public uploader: FileUploader = new FileUploader({
    url: this._emsConfig.apiEndpoint + '/assetAdmin/addtipsheets'
  });
  public hasBaseDropZoneOver: boolean = false;

  showImageDropzone: boolean;
  public volumeImageUploader: FileUploader = new FileUploader({
    url: this._emsConfig.apiEndpoint + '/assetAdmin/uploadvolumeimage'
  });
  public imageHasBaseDropZoneOver: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private _cmsService: CmsService,
    private _categoriesService: CategoriesService,
    private _utilitiesService: UtilitiesService,
    private _confirmService: ConfirmService,
    private _popupService: PopupService,
    public _emsConfig: EmsConfig,
    private _changeDetectionRef: ChangeDetectorRef,
    private _snackBar: SnackbarService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'updateOnpoint') {
            this.onpointSubmitForm(this.confirmOnpointData, this.confirmOnpointModuleData);
          } else if (this.confirmAction === 'updateModuleOnly') {
            this.createOrUpdateOnpointModule(this.confirmOnpointModuleData);
          }
        }
      }
    );
  }

  onEvent(event: any) {
    event.stopPropagation();
  }

  // ========================
  // get lists for dropdowns
  // ========================

  getCategories(): void {
    this._categoriesService.getAllCategories().subscribe(CategoryList => {
      this.categoryList = CategoryList['categories'];
    }, err => (this.errorMessage = <any>err));
  }

  getExpertsForDropdown(): void {
    this._cmsService.getAllExperts().subscribe(Expert => {
      this.expertList = Expert;
    }, err => (this.errorMessage = <any>err));
  }

    getSimpleVolumes(matchedLang): void {
    // second param is onPoint (true or false)
    this._cmsService.getAllVolumes(true, matchedLang).subscribe(
        SimpleVolume => {
          //filter out retired volumes EMSStatus: "retired"
          this.simpleVolumes = SimpleVolume.filter(x => x.EMSStatus !== 'retired');
        },
        err => this.errorMessage = <any>err
      );
  }


  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(
    component: string,
    action: string,
    volumeData: any,
    moduleData: any,
    message: string
  ): void {
    this.confirmAction = action;
    this.confirmOnpointData = volumeData;
    this.confirmOnpointModuleData = moduleData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  setPopupMessage(volumeCreate: boolean, volumeID: number, customError: string, categoryError: string, categoriesUpdated: boolean, inventoriesUpdated: boolean): void {
    let successMessage = 'The volume has been successfully created/updated.';
    let errorMessage = 'The volume has not been created/updated. ';

    // set messages variations
    if (categoryError !== '') {
      errorMessage = errorMessage + 'There was an error in adding/updating categories. Please try again.';
    }

        // check for linked volume error
    if (customError === 'Duplicate linked volume') {
      errorMessage = 'That volume has already been linked to another OnPoint. Please choose a different OnPoint and try again. Your changes have not been saved.'
      this._snackBar.error(errorMessage);
    } else if (customError !== 'Volume not created') {
      if (categoryError !== '') {
        successMessage = successMessage + ' However, there was an error in adding/updating additional categories.';
        this._snackBar.error(successMessage);
      } else {
        this._snackBar.success(successMessage);
      }
    } else {
      this._snackBar.error(errorMessage);
    }
  }

  // ==========================
  // get/update/create volumes
  // ==========================
  getOnpointDetails(): void {
    this._cmsService
      .getEmsVolume(this.id, true)
      .subscribe(OnpointDetailItem => {
        this.onpointDetails = OnpointDetailItem;
        console.log(this.onpointDetails);
        // set related categories and inventory
        this.onpointModule = this.onpointDetails.media[0];
        this.onPointDataIsLoaded=true;

        // if totally new onpoint (no volume or module has been created)
        if (this.id === -1) {
          this.addNewVolume = true;

          this.onpointModule = new ModuleItem();
          console.log(this.newModule);

          this.onpointDetails.volume.ID = 'N/A';
          this.onpointDetails.volume.DateCreated = '0001-01-01T00:00:00';
          this.onpointDetails.volume.AnnualReviewDate = '0001-01-01T00:00:00';
          // set default status to testing
          this.onpointDetails.volume.EMSStatus = 'testing';

          // new volumes won't have stored additional categories
          this.storedCategories = '';

          // get volumes list for matched dropdown
          this.getSimpleVolumes(null);

        } else if (this._utilitiesService.isBlank([this.onpointModule]) && this.onpointDetails.volume.ID !== 'N/A') {
          // if onpoint volume has been created but not the module
          this.onpointModule = new ModuleItem();
          this.updateModuleOnly = true;
          this.onpointModule.Title = this.onpointDetails.volume.VolumeTitle;
        } else {
          // if existing onpoint (volume & module)
          //show preview link
          this.showPreviewLink = true;            
          this.onpointPreviewLink = `${this._emsConfig.assetPreviewLink}/video/${this.onpointModule.AssetKey}`;
          try {
            this.buildTagArray(JSON.parse(this.onpointModule.Tags));
          }
          catch {
            console.log('Ne existing tags detected. Creating empty tag list');
            this.buildTagArray([]);
          }

          // trim time from dates
          if ( this.onpointDetails.volume.DateCreated !== '0001-01-01T00:00:00' ) {
            this.onpointDetails.volume.DateCreated = this._utilitiesService.trimDate(
              this.onpointDetails.volume.DateCreated
            );
          } else {
            this.onpointDetails.volume.DateCreated = '--';
          }

          // set editor data for tinymce
          if (this._utilitiesService.isBlank([this.onpointDetails.media[0].Transcripts])) {
            this.onpointDetails.media[0].Transcripts = '';
          } else {
            this.setTinyContent();
          }

          // matched volume
          this.matchedVolLang = this.onpointDetails.volume.Language;

          // set to be the opposite (if EN then it should only show FR volumes for matching)
          if (this.matchedVolLang === 'EN') {
            this.matchedVolLang = 'FR';
          } else {
            this.matchedVolLang = 'EN';
          }

          // matched volume field should be visible but disabled if volume lang is FR (since you can't match a volume FR to EN)
          this.showMatchedReadOnly = this.onpointDetails.volume.Language === 'FR';

          // get all simple volumes (for matched volume list)
          this.getSimpleVolumes(this.matchedVolLang);
        }
        // Get all subcategories by onpoint's category ID
        this.getAllSubcategoriesByCategory(this.onpointDetails.volume.CategoryID);
           // convert the string into an array & set video subcategories to true
           for (let i = 0; i < this.onpointModule.VideoSubcategories?.split(',').map(Number).length; i++) {
            this.videoSubcategoryIDs[this.onpointModule.VideoSubcategories?.split(',').map(Number)[i]] = true;
          }
        this.onPointInitialStatus = this.onpointDetails.volume.EMSStatus;
        // get the video file path in cloudberry
        this.videoLocation=`${this._emsConfig.LifeSpeakVideoPath}/VideosLowRes/${this.onpointDetails.volume.LibraryItemName}/${this.onpointModule.VideoUrl}.mp4`;
      }, err => (this.errorMessage = <any>err));
  }

  // if a completely new onpoint (no volume or module created)
  setupNewOnpoint(): void {
    this.addNewVolume = true;
    let newOnpoint = new VolumeDetailList();
    this.onpointModule = new ModuleItem();
    console.log(this.newModule);

    newOnpoint.ID = 'N/A';
    newOnpoint.DateCreated = new Date().toLocaleDateString();
    newOnpoint.AnnualReviewDate = '0001-01-01T00:00:00';
    // set default status to testing
    newOnpoint.EMSStatus = 'testing';

    // new volumes won't have stored additional categories
    this.storedCategories = '';

    this.onpointDetails = new VolumeDetailItem();
    this.onpointDetails.volume = newOnpoint;
  }

  onpointSubmitForm(onpointDetails, onpointModule): void {
    // set volume subject to match the onpoint title
    onpointDetails.volume.VolumeTitle = onpointModule.Title;
    // if no matched volume, set matched to 0 so it will be NULL in db
    if (this._utilitiesService.isBlank([onpointDetails.volume.MatchedVolume]) || onpointDetails.volume.MatchedVolume === -1 || onpointDetails.volume.MatchedVolume === 0) {
      onpointDetails.volume.MatchedVolume = 0;
    }

    // check that all fields are filled out before trying to create/update volume
    if (this._utilitiesService.isBlank([onpointModule.Title, onpointDetails.volume.Language]) || parseInt(onpointDetails.volume.CategoryID) === 0 || onpointDetails.volume.ExpertID === 0) {
      this._snackBar.error(this._emsConfig.text.EMS_Content.Complete_Fields_Error);
    } else {

      // if new volume, set id to -1 so it will create a new one
      if (onpointDetails.volume.ID === 0 || onpointDetails.volume.ID === 'N/A') {
        this.onpointDetails.volume.ID = this.id;
      }

      // onpoint is always true
      this.onpointDetails.volume.OnPoint = true;

      // set the volume subject to onpoint name
      this.onpointDetails.volume.VolumeTitle = this.onpointModule.Title;
      this.onpointDetails.volume.VolumeDescription = null;

      // onpoints don't have inventory data
      this.onpointDetails.volume.Inventory =null;
      this.onpointDetails.volume.InventoryUpdate = null;


      this._cmsService.addUpdateEmsVolume(onpointDetails.volume).subscribe(data => {
        // set the popup messages
        this.setPopupMessage( data.volumeCreate, data.volumeID, data.customError, data.categoryError, data.categoriesUpdated, data.inventoriesUpdated );

        // check for create or update or if there is an error with linked volume
        if (data.volumeCreate && data.customError === 'Volume not created' || !data.volumeCreate && data.customError === 'Duplicate linked volume') {
          // if volume not created, don't reset the volume details object by calling get volume details
          this.onpointDetails.volume.ID = 'N/A';
        } else if ( data.volumeCreate && data.customError !== 'Volume not created' ) {
          // this is to update the url with the new volume id
          const newData = data;
          console.log(newData);
          this.newOnpointID = newData.volumeId;

          onpointModule.VolumeID = this.newOnpointID;
          this.id = this.newOnpointID;

          this.createOrUpdateOnpointModule(onpointModule);
        } else {
          console.log('updated this volume - ' + data.volumeId);
          this.createOrUpdateOnpointModule(onpointModule);
        }
      }, err => (this.errorMessage = <any>err));
    }
  }

  // if the tag has changed in child component, find the index of that tag, and update the token and the keyword
  public updateTag($event) {
    console.log($event);
    this.tagBroadcast.next($event);
    this.tags[$event.index].Keyword_Token = $event.keyword.Keyword_Token;
    this.tags[$event.index].Keyword = $event.keyword.Keyword;

  }

  createOrUpdateOnpointModule(moduleDetails): void {
    this.onpointDetails.volume.ID = this.id;
    if (moduleDetails.ID === 'N/A') {
      moduleDetails.ID = -1;
    }

    moduleDetails.AllowLinkedClipImage = false; // this is always false for onpoints since they don't use images
    moduleDetails.SortOrder = Number(moduleDetails.SortOrder??'');
    moduleDetails.Transcripts = this.unEntity(tinymce.get('resource-text-area').getContent());
    moduleDetails.VolumeID = this.id;
    //process tags for upload
    if (this.tags.length > 0) {
      const selectedTags = this.tags.filter(e => e.Keyword_Token !== '-').map(e => e.Keyword_Token).join(',');
      moduleDetails.Tags = selectedTags;

    }
    // update the subcategory list for current onpoint
    moduleDetails.VideoSubcategories=this.updateVideoSubcategories();
    console.log('in the submit function');
    console.log(moduleDetails);
    this._cmsService.addUpdateModule(moduleDetails).subscribe(data => {
      console.log(data);

      if (data !== -1) {
        // check for create or update
        if ((moduleDetails.ID = -1)) {
          // update the url with the new onpoint id
          this.location.go('/cms/onpoints/' + this.id);
        } else {
          console.log('updated this module - ' + data.mediaClipId);
        }

        // get the onpoint details after create/update
        this.getOnpointDetails();

        this._snackBar.success(this._emsConfig.text.EMS_General.Popup_Success);
      } else {
        this._snackBar.error('OnPoint not created properly - please click the "Submit Module" button and try again.');
        this.updateModuleOnly = true;
      }
    }, err => (this.errorMessage = <any>err));
  }

  requestAmazonReupload() {
    this._cmsService.reuploadAmazonModule(this.onpointModule.ID).subscribe(result => {

      this.onpointModule.AmazonIsUploaded = false;
    },
      () => {
        window.alert('An error has occured - please try again later.');
      });

  }

  resetVideoSettings() {
    this.onpointModule.BrightCoveID = '-1';
    this.onpointModule.AmazonIsUploaded = false;

  }

  updateVideoContent() {

    this._cmsService.getBrightcoveID(this.onpointModule.VideoUrl).subscribe(data => {

      if (data.id === '-1') {

        alert(`Video with name ${this.onpointModule.VideoUrl} could not be found in brightcove library`);
      }
      else {
        this.onpointModule.BrightCoveID = data.id;
        this.requestAmazonReupload();
      }

    },
      () => {
        window.alert('An error has occured - please try again later.');
      });
  }

  // =======================================================
  // characters: &,>,< will be stored in non-entity form
  // =======================================================
  unEntity(text: string) {
    text = text.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    return text;
  }

  ngOnInit(): void {
    // gets the account id from url
    this.sub = this.route.params.subscribe(params => {
      this.id = Number(params['id']);
    });

    // get onpoint details on page load
    if (this.id === -1) {
      this.setupNewOnpoint();
    } else {
      this.getOnpointDetails();
    }

    // get stuff for dropdowns/checkboxes
    this.getExpertsForDropdown();
    this.getCategories();
  }

  private buildTagArray(currentTags: any[]) {
    // create an empty array to store tags
    this.tags = [];

    // add a record for each of the 4 tags. If there are records missing, add an empty tag
    for (let i = 0; i < 4; i++) {

      let currentTag = i <= (currentTags.length - 1) ? { Index: i, ...currentTags[i] } : { Keyword_Token: '-', 'Keyword': 'Not Selected', Index: i };
      this.tags.push(currentTag);
    }

  }

  setTinyContent() {
    if (this.onpointDetails?.media.length > 0 && this.onpointDetails.media[0].Transcripts) {
      tinymce.get('resource-text-area').setContent(this.onpointDetails.media[0].Transcripts);
    } else {
      tinymce.get('resource-text-area').setContent('');
    }
  }

  ngAfterViewInit() {
    tinymce.init({
      selector: '#resource-text-area',
      plugins: 'link code lists preview',
      toolbar:
        'code | undo redo | styles fontsize | forecolor | bullist numlist | bold italic underline | link unlink | alignleft aligncenter',
      font_size_formats: '10px 12px 14px 16px 18px 20px 24px',
      browser_spellcheck: true,
      min_height: 330,
      menubar: false,
      inline_styles: true,
      content_style: 'body {font-size: 14px;}',
      color_map: [
        '000000', 'Black',
        '666666', 'Gray',
      ],
      custom_colors: false,
      formats: {
        heading: { block: 'h3', styles: { fontWeight: '700', fontSize: '14px', lineHeight: '1.2', letterSpacing: '0.09px' } },
        underline: {inline: 'u'} // need this because they regular underline setting uses a style attribute which we strip out
      },
      style_formats: [
        { title: 'Heading', format: 'heading' },
      ]
    });
  }

    // ===================================================================================================
  // Called if Onpoint status changes. If status is changed to 'retired', for system admin it will
  // show a confirmation box and for non system admin, it will rollback to previous status automatically
  // ===================================================================================================
  onPointStatusChange(status: string): void {
    if (status === 'retired') {
      if (this._emsConfig.UserRole !== 'systemadmin') {
        // if not system admin then rollback to previous status
        this.onpointDetails.volume.EMSStatus = this.onPointInitialStatus;
        this._snackBar.error('Please contact administrator to retire this OnPoint');
      } else {
        this.showOnpointStatusConfirmation = (status === 'retired' && this.onPointInitialStatus !== 'retired') ? true : false;
      }
    }
  }

   // called if systemadmin does not confirm retiring the OnPoint
   goBackPreviousStatus(): void {
    this.onpointDetails.volume.EMSStatus = this.onPointInitialStatus;
    this.showOnpointStatusConfirmation = false;
  }

    // call api method to change OnPoint status to retire in both staging & Prod
    // and remove this onPoint from all client libraries (staging & prod)
    retireCurrentOnPoint(): void {
      this._cmsService.retireVolume(this.onpointDetails.volume.ID)
        .subscribe(data => {
          if (data && data.succeeded) {
            this._snackBar.success('OnPoint has been successfully retired & removed from all clients library');
          } else {
            this.onpointDetails.volume.EMSStatus = this.onPointInitialStatus;
            this._snackBar.error('There was an error while retiring OnPoint.', 10000);
          }
          this.showOnpointStatusConfirmation = false;
        },(error) => {
          console.log(error.error);
          this._snackBar.error('There was an error while retiring OnPoint.' + error.error?.message,10000);
      });
      }

  // ===============================
  // OnPoint's Subcategory Methods
  // ===============================
// get the subcategory list by onpoint category ID
getAllSubcategoriesByCategory(id:number):void{
  this._cmsService.getSubCategoriesByCategoryId(id)
    .subscribe(
      data => {
        this.subCategories = data;
        this.categoryName= this.categoryList.find(x=>x.ID==id).Name.English;
      },
      err => {
        this._snackBar.error();
        return;
      });
}

  // returns the updated subcategory IDs as a string
  updateVideoSubcategories():any{
    this.onpointModule.VideoSubcategories = '';
    var result = new Array();
    for (let i = 0; i < this.subCategories.length; i++) {
        // if the subcategory is marked true, then it will be to added to the array
      if(this.videoSubcategoryIDs[this.subCategories[i].ID])
      result.push(this.subCategories[i].ID);
    }
    return result.join(",");
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.confirmSubscription.unsubscribe();
    tinymce.remove(tinymce.get('resource-text-area'));
  }
}
