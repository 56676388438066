<div class="content-fusion-migration">
    <div class="page-ribbon">
        <h1>Migrate MHR content to Content Fusion</h1>
    </div>
    <p>These buttons allow EMS admins to instantly migrate MHR content to the new centralized Content database (instead of waiting for the overnight job to run) <br />
       Note: Experts and Taxonomy must be sent before any types of content (ex: Videos, Blogs, etc.)"<br />
    </p>

    <div class="section-padding">
        <div class="info-container__text-container">
            <button class="btn b-dark-blue ls-button-2"
                (click)="openConfirmDialog('contentfusion', 'expert-all', 'Are you sure you want to migrate all Expert data?')">Migrate All Experts</button>
            <button class="btn b-dark-blue ls-button-2"
                (click)="openConfirmDialog('contentfusion', 'expert-latest', 'Are you sure you want to migrate latest Expert data?')">Migrate Latest Experts</button>
            <button class="btn b-dark-blue ls-button-2"
                (click)="openConfirmDialog('contentfusion', 'taxonomy', 'Are you sure you want to migrate Taxonomy data?')">Migrate Taxonomy</button>
            <button class="btn b-dark-blue ls-button-2"
                (click)="openConfirmDialog('contentfusion', 'blog', 'Are you sure you want to migrate Blog data?')">Migrate Blogs</button>
            <button class="btn b-dark-blue ls-button-2"
                (click)="openConfirmDialog('contentfusion', 'video', 'Are you sure you want to migrate Video data?')">Migrate Videos</button>
        </div>
    </div>
</div>