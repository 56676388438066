<div class="onpoint-details-container section-padding">
  <div class="basic-container info-container volume-details-container__info-container">
    <syncstatus [includeChildrenByDefault]="true" [assetID]="onpointDetails?.volume.ID" [tableID]="8" ></syncstatus>
    <form class="addEditVolumeForm">
      <h3 class="ls-subheading f-dark-blue info-container__heading">OnPoint Details</h3>
      <div class="preview-link" *ngIf="showPreviewLink">
        <a [href]="onpointPreviewLink" target="_blank">Preview OnPoint</a>
      </div>
      <div class="details-container info-container__text-container">
        <div class="info-container__section-split">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z"
                  />
                </svg>
              </div>
              OnPoint ID
            </h4>
            <p class="basic-info__text setting__text ls-subheading">{{onpointModule?.ID}}</p>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field setting__heading--onpoint-title">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                </svg>
              </div>
              Title
              <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Content.Onpoint_Title_Tooltip" *ngIf="updateModuleOnly"></tooltip>
            </h4>
            <div class="input-container">
              <input type="text" class="basic-info__text setting__text-input text-input" name="moduleTitle" [attr.disabled]="updateModuleOnly?'':null" [(ngModel)]="onpointModule && onpointModule.Title">
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                </svg>
              </div>
              Video File Name   <tooltip class="video-url"   [tooltipMessage]="_emsConfig.text?.EMS_Content.Video_Url_Tooltip"></tooltip>
            </h4>
            <div class="input-container">
              <input type="text" (blur)="resetVideoSettings()"  class="basic-info__text setting__text-input text-input" name="moduleVideoUrl"  [(ngModel)]="onpointModule && onpointModule.VideoUrl">
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg height="20" fill="#BBBBBB" viewBox="0 0 24 24">
                  <path d="M12,13C9.33,13 4,14.33 4,17V20H20V17C20,14.33 14.67,13 12,13M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4M12,14.9C14.97,14.9 18.1,16.36 18.1,17V18.1H5.9V17C5.9,16.36 9,14.9 12,14.9M12,5.9A2.1,2.1 0 0,1 14.1,8A2.1,2.1 0 0,1 12,10.1A2.1,2.1 0 0,1 9.9,8A2.1,2.1 0 0,1 12,5.9Z"
                  />
                </svg>
              </div>
              Allow Guest Preview
            </h4>
            <div class="basic-info__checkbox-container">
              <div class="checkbox-container">
                <div class="ls-radio">
                  <input name="allowGuestPreview" [(ngModel)]="onpointModule && onpointModule.AllowGuestPreview" type="radio" [value]=true
                    id="allowTrue">
                  <label for="allowTrue">
                    <span></span>
                  </label>
                </div>
                Yes
              </div>
              <div class="checkbox-container">
                <div class="ls-radio">
                  <input name="allowGuestPreview" [(ngModel)]="onpointModule && onpointModule.AllowGuestPreview" type="radio" [value]=false
                    id="allowFalse">
                  <label for="allowFalse">
                    <span></span>
                  </label>
                </div>
                No
              </div>
            </div>
          </div>
          <div class="basic-info__setting" *ngIf="showPreviewLink">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                </svg>
              </div>
              Asset Key
            </h4>
            <p class="basic-info__text setting__text ls-subheading">{{ onpointModule?.AssetKey }}</p>
          </div>
          <div class="basic-info__setting amazon-upload-container">
              <h4 class="basic-info__heading setting__heading ls-subheading">
                <div class="svg-icon svg-baseline">
                  <svg height="20" fill="#BBBBBB" viewBox="0 0 24 24">
                    <path d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z"
                    />
                  </svg>
                </div>
                {{_emsConfig.text?.EMS_Content.Amazon_Upload}}
              </h4>
              <div class="upload-info-container">
              <p class="basic-info__text setting__text ls-subheading">{{ AmazonIsUploadedString }}.<br/>
              {{ BrightcoveIsUploadedString }}
              </p>
              <div class="btn--tooltip onpoint__btn--tooltip">
                <button class="btn--amazon-upload" (click)="updateVideoContent()"> Update Video Content</button>
                <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Content.Amazon_Reupload_Tooltip"></tooltip>
              </div>
            </div>
            <div class="video-path-container">
              <h4 class="basic-info__heading setting__heading ls-subheading">
                <div class="svg-icon svg-baseline">
                  <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                    <path
                      d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                  </svg>
                </div>
                Video file path location: <tooltip class="video-url"
                  [tooltipMessage]="_emsConfig.text?.EMS_Content.Video_File_Path_Tooltip"></tooltip>
              </h4>
              <p>{{videoLocation}}</p>
            </div>
            </div>
            <div class="basic-info__setting">
              <video-participation [mediaType]="'onpoint'" [assetId]="id"></video-participation>
            </div>
            <div class="basic-info__setting" *ngIf="onPointDataIsLoaded">
            <app-subtitle-details [assetId]="onpointModule?.ID" [currentVideoTitle]="onpointModule?.Title" [Language]="onpointDetails?.volume.Language" [TableID]="8" [VolumeID]="onpointDetails?.volume.ID"></app-subtitle-details>
            </div>
        </div>

        <!--second half-->
        <div class="info-container__section-split">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                </svg>
              </div>
              Subcategories
            </h4>
            <div class="video-subcategory-category">Volume Category: <span>{{categoryName}}</span></div>
            <ai-ui 
              *ngIf="onpointModule?.Transcripts && subCategories && subCategories.length > 0"
              [prompt]="aiPromptType.Subcategories"
              [transcript]="onpointModule?.Transcripts"
              [list]="subCategories"
              (emitResponse)="videoSubcategoryIDs = $event">
            </ai-ui>
            <ul class="subcategory-list-container">
              <li *ngFor="let subcategory of subCategories">
                <div class="checkbox-container">
                  <div class="ls-checkbox">
                    <mat-checkbox name="subCategory{{subcategory.ID}}" [(ngModel)]="videoSubcategoryIDs[subcategory.ID]"
                      [color]="'primary'" id="subCategory{{subcategory.ID}}" value="{{subcategory.ID}}">
                      {{subcategory.Name}}</mat-checkbox>
                  </div>

                </div>
              </li>
            </ul>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z"
                  />
                </svg>
              </div>
              Keywords
            </h4>

            <ai-ui 
              *ngIf="onpointModule?.Transcripts"
              [prompt]="aiPromptType.Keywords"
              [transcript]="onpointModule?.Transcripts"
              (emitResponse)="onpointModule.KeyWords = $event">
            </ai-ui>

            <div class="input-container">
              <textarea name="module-keywords" id="module-keyword" rows="6" class="setting__textarea textarea-input" [(ngModel)]="onpointModule && onpointModule.KeyWords"></textarea>
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                </svg>
              </div>
           Tags   <tooltip class="video-url"  [tooltipMessage]="_emsConfig.text?.EMS_Content.Video_Tag_Tooltip"></tooltip>
            </h4>
            <div class="input-container" *ngIf="onpointModule?.KeyWords && onpointModule?.KeyWords!==''">
           <div *ngFor="let tag of tags">

            <tag-selector [keywordToken]="tag.Keyword_Token" [module]="onpointModule?.ID" [keyword]="tag.Keyword" [index]="tag.Index" (selected)="updateTag($event)" [notification]="tagBroadcast"></tag-selector>
           </div>
            </div>
            <div class="input-container" *ngIf="!onpointModule?.KeyWords || onpointModule.KeyWords===''">
              {{_emsConfig.text?.EMS_Content.Video_Tag_Not_Available}}
              </div>
          </div>
          <div class="basic-info__setting transcript-setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z"
                  />
                </svg>
              </div>
              Transcript
            </h4>
            <div class="input-container">
              <textarea id="resource-text-area" [innerHTML]="onpointModule?.Transcripts"></textarea>
            </div>
          </div>
        </div>
        <div class="form-button-container">
          <button (click)="openConfirmDialog('onpointDetails', 'updateModuleOnly', onpointDetails, onpointModule, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
            type="button" class="btn volume-submit modile-only-submit" *ngIf="updateModuleOnly">
            Submit Module
          </button>
        </div>
      </div>

      <h3 class="volume-level-heading ls-subheading f-dark-blue info-container__heading">OnPoint Volume Level Details</h3>
      <div class="details-container info-container__text-container">
        <div class="info-container__section-split">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
                </svg>
              </div>
              Volume ID (used for matching OnPoints)
            </h4>
            <p class="basic-info__text setting__text ls-subheading">{{onpointDetails?.volume.ID}}</p>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
              <span class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"/>
                </svg>
              </span>
              Language
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" name="volumeLanguage" [attr.disabled]="updateModuleOnly?'':null" [(ngModel)]="onpointDetails?.volume.Language">
                <option value="EN">EN</option>
                <option value="FR">FR</option>
              </select>
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
                  />
                </svg>
              </div>
              OnPoint Volume Status
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" name="volumeStatus" [attr.disabled]="updateModuleOnly?'':null"
              [(ngModel)]="onpointDetails?.volume.EMSStatus"
              (ngModelChange)="onPointStatusChange(onpointDetails?.volume.EMSStatus)">
                <option value="live">Active</option>
                <option value="retired">Retired</option>
                <option value="testing">Testing</option>
                <option value="qa">QA</option>
              </select>
            </div>

            <div class="volume-detail-retired-volume-box" *ngIf="showOnpointStatusConfirmation===true">
              <p>Are you sure you want to retire this OnPoint? It will be removed from all client libraries!<br/>
                Syncing is not required for this action.</p>
              <div class="confirmation-button-container">
                <button class="btn warning" (click)="retireCurrentOnPoint()">YES!</button>
                <button class="btn" (click)="goBackPreviousStatus()">NO</button>
              </div>
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z" />
                </svg>
              </div>
              Sort Order
            </h4>
            <div class="input-container">
              <input type="text" class="basic-info__text setting__text-input text-input" name="volumeSortOrder" [attr.disabled]="updateModuleOnly?'':null" [(ngModel)]="onpointDetails?.volume.SortOrder">
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                </svg>
              </div>
              Matched OnPoint Volume
            </h4>
            <div class="select-container" [ngClass]="{'volume-matched-dropdown-disabled': showMatchedReadOnly}">
              <select *ngIf="onpointDetails" class="setting__select-input select-input volume-matched-dropdown" name="volumeMatched" [disabled]="showMatchedReadOnly" [(ngModel)]="onpointDetails?.volume.MatchedVolume">
                <option [ngValue]="0">Select a matched volume</option>
                <option [ngValue]="matchedVol.ID" *ngFor="let matchedVol of simpleVolumes">
                  {{matchedVol.VolumeTitle}}</option>
              </select>
            </div>
          </div>
        </div>

        <!--second half-->
        <div class="info-container__section-split">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"/>
                    <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
              </div>
              Date Created
            </h4>
            <p class="basic-info__text setting__text">{{onpointDetails?.volume.DateCreated}}</p>
          </div>

          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                  />
                </svg>
              </div>
              Expert
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" name="volumeSpeaker" [attr.disabled]="updateModuleOnly?'':null" [(ngModel)]="onpointDetails?.volume.ExpertID">
                <option value="-1" disabled>Select an expert</option>
                <option value="{{expert.ExpertID}}" *ngFor="let expert of expertList">{{expert.ExpertName.English}}</option>
              </select>
            </div>
          </div>

          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
                </svg>
              </div>
              Category
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" name="volumeCategory" [attr.disabled]="updateModuleOnly?'':null" [(ngModel)]="onpointDetails?.volume.CategoryID">
                <option value="{{category.ID}}" *ngFor="let category of categoryList">{{category.Name.English}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-button-container">
          <button (click)="openConfirmDialog('onpointDetails', 'updateOnpoint', onpointDetails, onpointModule, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)" type="button" class="btn volume-submit" *ngIf="!updateModuleOnly">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>

</div>
