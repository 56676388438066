<div class="module-details-container section-padding">

  <button class="btn btn--upload go-to-volume" (click)="goToVolume(moduleDetails?.VolumeID)">Go to volume</button>

  <div class="basic-container info-container module-details-container__info-container">


    <syncstatus [assetID]="moduleDetails?.ID" [tableID]="7" [parentID]="moduleDetails?.VolumeID"></syncstatus>

    <h3 class="ls-subheading f-dark-blue info-container__heading">Module Details</h3>
    <div class="preview-link" *ngIf="showPreviewLink">
      <a [href]="videoPreviewLink" target="_blank">Preview Video</a>
    </div>
    <div class="details-container info-container__text-container">
      <form class="addEditModuleForm">
        <div class="info-container__section-split">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
                </svg>
              </div>
              Module ID
            </h4>
            <p class="basic-info__text setting__text ls-subheading">{{moduleDetails?.ID}}</p>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                </svg>
              </div>
              Title
            </h4>
            <div class="input-container">
              <input type="text" class="basic-info__text setting__text-input text-input" name="moduleTitle"
                [(ngModel)]="moduleDetails && moduleDetails.Title">
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                </svg>
              </div>
              Video File Name <tooltip class="video-url"
                [tooltipMessage]="_emsConfig.text?.EMS_Content.Video_Url_Tooltip"></tooltip>
            </h4>
            <div class="input-container">
              <input type="text" (blur)="resetVideoSettings()" class="basic-info__text setting__text-input text-input"
                name="moduleVideoUrl" [(ngModel)]="moduleDetails && moduleDetails.VideoUrl">

            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                </svg>
              </div>
              Associated Volume
            </h4>
            <div class="select-container">
              <select class="setting__select-input select-input" name="associatedVolume"
                [(ngModel)]="moduleDetails && moduleDetails.VolumeID">
                <option [ngValue]="-1" disabled>Select an associated volume</option>
                <option [ngValue]="assocVol.ID" *ngFor="let assocVol of simpleVolumes">{{assocVol.VolumeTitle}}</option>
              </select>
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                </svg>
              </div>
              Volume Status
            </h4>
            <p class="basic-info__text setting__text ls-subheading">{{moduleDetails?.VolumeStatus | titlecase}}</p>
          </div>
          <div class="basic-info__setting" *ngIf="showPreviewLink">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                </svg>
              </div>
              Asset Key
            </h4>
            <p class="basic-info__text setting__text ls-subheading">{{ moduleDetails?.AssetKey }}</p>
          </div>

          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading required-field">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z" />
                </svg>
              </div>
              Sort Order
            </h4>
            <div class="input-container">
              <input type="text" class="basic-info__text setting__text-input text-input" name="volumeSortOrder"
                [(ngModel)]="moduleDetails && moduleDetails.SortOrder">
            </div>
          </div>
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg height="20" fill="#BBBBBB" viewBox="0 0 24 24">
                  <path
                    d="M12,13C9.33,13 4,14.33 4,17V20H20V17C20,14.33 14.67,13 12,13M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4M12,14.9C14.97,14.9 18.1,16.36 18.1,17V18.1H5.9V17C5.9,16.36 9,14.9 12,14.9M12,5.9A2.1,2.1 0 0,1 14.1,8A2.1,2.1 0 0,1 12,10.1A2.1,2.1 0 0,1 9.9,8A2.1,2.1 0 0,1 12,5.9Z" />
                </svg>
              </div>
              Allow Guest Preview
            </h4>
            <div class="basic-info__checkbox-container">
              <div class="checkbox-container">
                <div class="ls-radio">
                  <input name="allowGuestPreview" [(ngModel)]="moduleDetails && moduleDetails.AllowGuestPreview"
                    type="radio" [value]=true id="allowTrue">
                  <label for="allowTrue"><span></span></label>
                </div>
                Yes
              </div>
              <div class="checkbox-container">
                <div class="ls-radio">
                  <input name="allowGuestPreview" [(ngModel)]="moduleDetails && moduleDetails.AllowGuestPreview"
                    type="radio" [value]=false id="allowFalse">
                  <label for="allowFalse"><span></span></label>
                </div>
                No
              </div>
            </div>
          </div>

          <div class="basic-info__setting upload-setting image-upload__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
                </svg>
              </div>
              Module Image
              <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Content.Tipsheet_Tooltip"></tooltip>
            </h4>
            <div class="upload-setting__container">
              <div ng2FileDrop [ngClass]="{'nv-file-over': imageHasBaseDropZoneOver}"
                (fileOver)="fileOverBaseImage($event)" [uploader]="moduleImageUploader"
                class="well my-drop-zone upload-container__dropzone" *ngIf="!imageLoaded">
                Drag and drop file here
              </div>
              <div class="upload-setting__file-container" *ngFor="let item of moduleImageUploader.queue">
                <div class="upload-setting__file-name-btns"
                  *ngIf="moduleImageUploader?.queue?.length > 0 && !imageLoaded">
                  <span>{{item?.file?.name}}</span>
                  <div class="upload-btn-container">
                    <button type="button" class="btn btn-success btn-xs" (click)="item.upload()"
                      (mousedown)="uploadingGifAdd()" [disabled]="item.isReady || item.isUploading || item.isSuccess">
                      Upload
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()">
                      Remove
                    </button>
                  </div>
                </div>
              </div>

              <div class="upload-container__existing-image" *ngIf="imageLoaded">
                <span>{{moduleDetails.ImageUrl}}.jpg</span>
                <button type="button" class="btn btn-remove btn-xs" (click)="previewModuleImage()">Preview</button>
                <button type="button" class="btn btn-remove btn-xs" (click)="clearModuleImage()"
                  *ngIf="imageLoaded">Remove</button>
                <button type="button" class="btn btn-remove btn-xs" (click)="removeImageFromAWS(moduleDetails.ImageUrl)"
                  *ngIf="imageLoaded">Remove uploaded image from
                  AWS</button>
                <p class="image-in-queue" *ngIf="imageLoaded">* Your new image has
                  been added to the processing queue and will replace the old image once processed. Please allow 5
                  minutes for processing.</p>
              </div>
            </div>
          </div>
          <div class="basic-info__setting amazon-upload-container">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg height="20" fill="#BBBBBB" viewBox="0 0 24 24">
                  <path
                    d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
                </svg>
              </div>
              {{_emsConfig.text?.EMS_Content.Amazon_Upload}}
            </h4>
            <div class="upload-info-container">
            <p class="basic-info__text setting__text ls-subheading">{{ AmazonIsUploadedString }}.<br />
              {{ BrightcoveIsUploadedString }}
            </p>
            <div class="btn--tooltip onpoint__btn--tooltip">
              <button class="btn--amazon-upload" (click)="updateVideoContent()"> Update Video Content</button>
              <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Content.Amazon_Reupload_Tooltip"></tooltip>
            </div>
          </div>
          <div class="video-path-container">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                </svg>
              </div>
              Video file path location: <tooltip class="video-url"
                [tooltipMessage]="_emsConfig.text?.EMS_Content.Video_File_Path_Tooltip"></tooltip>
            </h4>
            <p>{{videoLocation}}</p>
          </div>


          <!-- Speech-to-Text & Audio-To-Text with translations POC-->
          <!-- TODO - TEMPORARY POC CODE -->
          <div *ngIf="enableAI">
            <div style="padding: 12px 0;">
              <h3>Speech Recognition</h3>
              <div style="padding: 12px 0;">
                <div style="display: flex;">
                  <button type="button" (click)="_speechRecognitionService.startListening()" [disabled]="_speechRecognitionService.isListening" 
                    class="btn btn-xs ls-button-1" style="float:none;margin-right:12px;display:flex;align-items:center;">
                    <svg id="microphone-on" fill="#FFFFFF" width="20px" height="20px" style="margin-right:8px;" viewBox="0 0 512 512"><g><g><path d="m439.5,236c0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4c0,70-64,126.9-142.7,126.9-78.7,0-142.7-56.9-142.7-126.9 0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4c0,86.2 71.5,157.4 163.1,166.7v57.5h-23.6c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h88c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-23.6v-57.5c91.6-9.3 163.1-80.5 163.1-166.7z"/><path d="m256,323.5c51,0 92.3-41.3 92.3-92.3v-127.9c0-51-41.3-92.3-92.3-92.3s-92.3,41.3-92.3,92.3v127.9c0,51 41.3,92.3 92.3,92.3zm-52.3-220.2c0-28.8 23.5-52.3 52.3-52.3s52.3,23.5 52.3,52.3v127.9c0,28.8-23.5,52.3-52.3,52.3s-52.3-23.5-52.3-52.3v-127.9z"/></g></g></svg>  
                    Start Listening
                  </button>
                  <button type="button" (click)="_speechRecognitionService.stopListening()" [disabled]="!_speechRecognitionService.isListening" 
                    class="btn btn--delete-bg btn-xs ls-button-1" style="float:none;display:flex;align-items:center;">
                      <svg id="microphone-off" width="20px" height="20px" style="margin-right:8px;" viewBox="-3.5 0 32 32"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"><g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-154.000000, -307.000000)" fill="#FFFFFF"><path d="M167,333 C165.061,333 163.236,332.362 161.716,331.318 L160.31,332.742 C161.944,333.953 163.892,334.765 166,334.955 L166,337 L165,337 C164.448,337 164,337.448 164,338 C164,338.553 164.448,339 165,339 L169,339 C169.552,339 170,338.553 170,338 C170,337.448 169.552,337 169,337 L168,337 L168,334.955 C172.938,334.51 177.117,330.799 178,326 L176,326 C175.089,330.007 171.282,333 167,333 L167,333 Z M167,329 C166.136,329 165.334,328.761 164.625,328.375 L163.168,329.85 C164.27,330.572 165.583,331 167,331 C170.866,331 174,327.866 174,324 L174,318.887 L172,320.911 L172,324 C172,326.762 169.761,329 167,329 L167,329 Z M162,314 C162,311.238 164.239,309 167,309 C169.174,309 171.005,310.396 171.694,312.334 L173.198,310.812 C172.035,308.558 169.711,307 167,307 C163.134,307 160,310.134 160,314 L160,324 C160,324.053 162,322.145 162,322.145 L162,314 L162,314 Z M177.577,310.013 L153.99,332.597 L155.418,334.005 L179.014,311.433 L177.577,310.013 L177.577,310.013 Z M158.047,326.145 C158.035,326.095 158.011,326.05 158,326 L156,326 C156.109,326.596 156.271,327.175 156.478,327.733 L158.047,326.145 L158.047,326.145 Z" sketch:type="MSShapeGroup"></path></g></g></svg>
                      Stop Listening
                  </button>
                </div>

                <div style="padding: 12px 0;">
                    <strong>Recognized Transcript: </strong> <span style="color:green">{{ _speechRecognitionService.speechTranscript }}</span>
                </div>
              </div>
            </div>
            <div style="padding: 12px 0;">
              <h3>Video Closed Captioning</h3>
              <div>
                  <input type="file" (change)="_speechRecognitionService.onFileChange($event)">
              </div>
              <div>
                  <h3>Converted VTT Content</h3>
                  <div style="display:flex">
                    <div>
                      <button (click)="_speechRecognitionService.generateCaptions()" [disabled]="_speechRecognitionService.isProcessing || !_speechRecognitionService.mediaFile" class="btn btn-xs ls-button-1" type="button">  
                        Generate EN Captions
                      </button>
                      <div *ngIf="_speechRecognitionService.isProcessing">Processing...</div>
                      <textarea rows="10" cols="50" [(ngModel)]="_speechRecognitionService.vttContent" [ngModelOptions]="{standalone:true}" [disabled]="!_speechRecognitionService.vttContent"></textarea>
                      <button (click)="_speechRecognitionService.downloadVttFile()" class="btn btn-xs ls-button-1" type="button">
                        Download EN VTT
                      </button>
                    </div>
                    <div>
                      <button (click)="_speechRecognitionService.sendForTranslation()" [disabled]="!_speechRecognitionService.encodedWavFile" type="button" class="btn btn-xs ls-button-1">
                        Translate into FR Captions
                      </button>
                      <textarea rows="10" cols="50" [(ngModel)]="_speechRecognitionService.translatedContent" [ngModelOptions]="{standalone:true}" [disabled]="!_speechRecognitionService.translatedContent"></textarea>
                      <button (click)="_speechRecognitionService.downloadVttFile()" class="btn btn-xs ls-button-1" type="button">
                        Download FR VTT
                      </button>
                    </div>
                  </div>
              </div>
            </div>
            <div style="padding: 12px 0;">
              <h3>Translation</h3>
              <div style="padding: 12px 0;">
                <button (click)="_speechRecognitionService.sendForTranslation()" style="float:none;margin-right:12px;" type="button" class="btn btn-xs ls-button-1">Send for Translation</button>
                <div style="padding: 12px 0;">
                </div>
              </div>
            </div>
          </div>
          <!------------------->


          </div>
          <app-subtitle-details *ngIf="moduleDataIsLoaded" [assetId]="moduleDetails?.ID" [currentVideoTitle]="moduleDetails?.Title" [Language]="clipLanguage" [TableID]="7" [VolumeID]="moduleDetails?.VolumeID"></app-subtitle-details>
        </div>

        <!--second half-->
        <div class="info-container__section-split">
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                </svg>
              </div>
              Subcategories
            </h4>

            <ai-ui 
              *ngIf="moduleDetails?.Transcripts && subCategories.length > 0"
              [prompt]="aiPromptType.Subcategories"
              [transcript]="moduleDetails?.Transcripts"
              [list]="subCategories"
              (emitResponse)="videoSubcategoryIDs = $event">
            </ai-ui>

            <ul class="subcategory-list-container">
              <li *ngFor="let subcategory of subCategories">
                <div class="checkbox-container">
                  <div class="ls-checkbox">
                    <mat-checkbox name="subCategory{{subcategory.ID}}" [(ngModel)]="videoSubcategoryIDs[subcategory.ID]"
                      [color]="'primary'" id="subCategory{{subcategory.ID}}" value="{{subcategory.ID}}">
                      {{subcategory.Name}}</mat-checkbox>
                  </div>

                </div>
              </li>
            </ul>
            
          </div>
          
          <!-- <div class="basic-info__setting">
			  		<button *ngIf="enableAI" title="User AI to detect the module category" type="button" class="aibutton" (click)="detectCategories(moduleDetails?.VolumeID, moduleDetails?.ID)" [disabled]="networkCall">
                   <img src="assets/svg/AI_icon_white.svg" width="24">️Detect Categories
            	</button>
            <button *ngIf="enableAI" title="Use AI to ask more questions about the module content" type="button" class="aibutton" (click)="detectCategoriesCustom(moduleDetails?.VolumeID, moduleDetails?.ID)" [disabled]="networkCall">
						<img src="assets/svg/AI_icon_white.svg" width="24">Change question
						</button>
          </div> -->
          
          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                </svg>
              </div>
              Keywords
            </h4>

            <ai-ui
              *ngIf="moduleDetails?.Transcripts"
              [prompt]="aiPromptType.Keywords"
              [transcript]="moduleDetails?.Transcripts"
              (emitResponse)="moduleDetails.KeyWords = $event">
            </ai-ui>

            <div class="input-container">
              <textarea name="module-keywords" id="module-keyword" rows="6" class="setting__textarea textarea-input"
                [(ngModel)]="moduleDetails && moduleDetails.KeyWords"></textarea>
            </div>
          </div>

          <div class="basic-info__setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                </svg>
              </div>
              Tags <tooltip class="video-url" [tooltipMessage]="_emsConfig.text?.EMS_Content.Video_Tag_Tooltip">
              </tooltip>
            </h4>
            <div class="input-container" *ngIf="moduleDetails?.KeyWords && moduleDetails?.KeyWords!==''">
              <div *ngFor="let tag of tags">

                <tag-selector [keywordToken]="tag.Keyword_Token" [module]="moduleDetails?.ID" [keyword]="tag.Keyword"
                  [index]="tag.Index" (selected)="updateTag($event)" [notification]="tagBroadcast"></tag-selector>
              </div>
            </div>
            <div class="input-container" *ngIf="!moduleDetails?.KeyWords || moduleDetails.KeyWords===''">
              {{_emsConfig.text?.EMS_Content.Video_Tag_Not_Available}}
            </div>
          </div>
					<!-- <div>
						<button *ngIf="enableAI" title="Use AI to detect module keywords" type="button" class="aibutton"
							(click)="detectKeywords(moduleDetails?.VolumeID, moduleDetails?.ID)" [disabled]="networkCall">
							<img src="assets/svg/AI_icon_white.svg" width="24">Detect Keywords
						</button>
						<button *ngIf="enableAI" title="Use AI to ask more questions about the module content" type="button" class="aibutton"
							(click)="detectTagsCustom(moduleDetails?.VolumeID, moduleDetails?.ID)" [disabled]="networkCall">
							<img src="assets/svg/AI_icon_white.svg" width="24">Change question
						</button>
					</div> -->
			
          <div class="basic-info__setting transcript-setting">
            <h4 class="basic-info__heading setting__heading ls-subheading">
              <div class="svg-icon svg-baseline">
                <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                  <path
                    d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" />
                </svg>
              </div>
              Transcript
            </h4>
            <div class="input-container">
              <textarea id="resource-text-area" [innerHTML]="moduleDetails?.Transcripts"></textarea>
            </div>
          </div>

          <div class="basic-info__setting">
            <video-participation [mediaType]="'module'" [assetId]="id"></video-participation>
          </div>
        </div>
        <div class="form-button-container">
          <button
            (click)="openConfirmDialog('moduleDetails', 'updateModule', moduleDetails, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
            type="button" class="btn module-submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="img-preview-modal modal-window" *ngIf="showImgPreview" (click)="closePreviewModal()">
    <div class="modal-container img-modal-container" (click)="onEvent($event)">
      <div class="modal__title-container b-dark-blue">
        <h3 class="welcome-page-modal__title modal__title ls-headline f-white">Module Image Preview</h3>
        <button class="welcome-page-modal__close modal__close" (click)="closePreviewModal()">
          <svg viewBox="0 0 24 24">
            <path fill="#ffffff"
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
          </svg>
        </button>
      </div>
      <div class="img-preview-content-container">
        <div class="img-container">
          <img [src]="imgPreviewUrl" (error)="updateImageUrl($event)" alt="">
        </div>
      </div>
    </div>
  </div>

</div>
